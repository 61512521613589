import { useState } from 'react';
import Button from './common/Button';

const Login = ({ fetchData }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const url = '/api/auth/login';

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });
    const data = await response.json();
    if (data.valid) {
      fetchData();
    } else {
      if (email && password) {
        alert("Dados incorretos!");
      }
    }
  };

  return (
    <section className="vh-100">
      <div className="container-fluid h-custom">
        <div className="row d-flex justify-content-center align-items-center h-25">
          <div className="col-6 text-center">
            <img src="https://estudarnoexterior.com.br/logo-horizontal.png"
              className="img-fluid" />
          </div>
        </div>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-8 col-lg-6 col-xl-3">
            <form onSubmit={handleSubmit}>
              <div className="form-outline mb-4">
                <label className="form-label text-uppercase fw-bold fs-6" htmlFor="username">Usuário</label>
                <input type="text" id="username" className="form-control form-control-lg p-2"
                  placeholder="Digite seu e-mail de acesso" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>

              <div className="form-outline mb-3">
                <label className="form-label text-uppercase fw-bold fs-6" htmlFor="password">Senha</label>
                <input type="password" id="password" className="form-control form-control-lg p-2"
                  placeholder="Digite sua senha" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>

              <div className="text-center text-lg-start mt-4 pt-2">
                <Button type="submit" id="login-button" color="info" size="lg" label="Login"/>
              </div>

            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Login;
