import "bootstrap/dist/css/bootstrap.css";
import "./assets/css/paper-dashboard.css?v=1.3.0";
import "./assets/css/style.css";
import "./assets/css/extra.css";

import { Routes, Route } from "react-router-dom";
import Crm from './components/Crm';
import Fallback from './components/Fallback';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Fallback/> } />
        <Route path="/app/crm" element={ <Crm/> } />
      </Routes>
    </div>
  )
}

export default App;
