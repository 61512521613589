import { useState, useEffect } from 'react';
import Login from "./Login";
import { UserContext } from '../contexts/UserContext';

const Auth = ({ children }) => {
  const [userData, setUserData] = useState({});

  const url = "/api/auth";

  //fetch data from API
  const fetchData = async () => {
    const response = await fetch(url);
    const data = await response.json();

    if (data.valid) {
      setUserData(data.userdata);
    } else {
      console.log('not logged in');
    }
  };

  //fetch data from API only once with useEffect
  useEffect(() => {
    fetchData();
  }, []);

  return userData.nome
    ? <UserContext.Provider value={{userData, logout:() => setUserData({})}}>{children}</UserContext.Provider>
    : <Login fetchData={fetchData} />;
};

export default Auth;
