import { useState, useEffect } from 'react';
import Base from './common/Base';
import Button from './common/Button';

const Crm = () => {
  const [crmData, setCrmData] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [filtro1, setFiltro1] = useState("cliente.email_cliente");
  const [filtro2, setFiltro2] = useState("cliente.email_cliente");
  const [valor1, setValor1] = useState('');
  const [valor2, setValor2] = useState('');
  const [favoritos, setFavoritos] = useState(false);
  const [atrasados, setAtrasados] = useState(false);

  const fetchData = async () => {
    const url = '/api/busca-crm';

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        f1: filtro1,
        f2: filtro2,
        v1: valor1,
        v2: valor2,
        f: favoritos,
        a: atrasados
      }),
    });
    const data = await response.json();
    setCrmData(data.resultados);
    setError(data.erro);
    setIsLoading(false);
  };

  const clienteFavorito = async (id_cliente, favorito) => {
    const response = await fetch(`/api/cliente-favorito/${id_cliente}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ favorito }),
    });
    const data = await response.json();
    if (!data.erro) {
      fetchData();
    } else {
      alert("Erro ao atualizar favorito!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderItems = () => {
    return (
      <>
        {crmData.map((item) => (
          <tr className={item.class}>
            <td><a target="_blank" href={`https://estudarnoexterior.com.br/admin/clientes/editar/id/${item.id_cliente}`}>{item.nome_cliente}</a></td>
            <td>{item.email_cliente}</td>
            <td>{item.nom_cidade}/{item.sgl_estado}</td>
            <td>{item.registrado_para}</td>
            <td>{item.nome_status_negociacao}</td>
            <td>{item.data_lembranca}</td>
            <td>
              {item.favorito == 0
                ? <a onClick={() => clienteFavorito(item.id_cliente, 1)} className="icon-button" title="favorito"><img src="/icons/star-regular.svg" /></a>
                : <a onClick={() => clienteFavorito(item.id_cliente, 0)} className="icon-button-yellow" title="favorito"><img src="/icons/star-solid.svg" /></a>
              }
              <a target="_blank" href={`/manager/resumo/id/${item.id_cliente}`} className="icon-button" title="crm do cliente"><img src="/icons/search.svg" /></a>
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <Base title="CRM">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div id="error-message" className="is-hidden notification is-danger mt-1"></div>
              <div id="success-message" className="is-hidden notification is-success mt-1"></div>

              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <ul className="nav nav-tabs" data-tabs="tabs">
                    <li className="nav-item">
                      <a className="nav-link active" href="#crm" data-toggle="tab">CRM</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#lembretes" data-toggle="tab">Lembretes</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="tab-content">
                <div className="tab-pane active" id="crm">
                  <br />
                  <div>
                    <div className="filter-wrap row mb-1">
                      <div className="col-lg-6">
                        <div className="row ml-0">
                          <div className="col pl-0">
                            <select onChange={e => setFiltro1(e.target.value)} value={filtro1} id="filtro1" name="filtro" className="form-control">
                              <option value="cliente.email_cliente">E-mail cliente</option>
                              <option value="cliente.nome_cliente">Nome cliente</option>
                              <option value="status_negociacao.nome_status">Status do cliente</option>
                              <option value="crm.data_registro">Data de registro</option>
                              <option value="crm.data_lembranca">Data de lembrança</option>
                              <option value="newsletter">Newsletter</option>
                              <option value="quiz">Quiz</option>
                            </select>
                          </div>
                          <div className="col">
                            <input onChange={e => setValor1(e.target.value)} value={valor1} id="valor1" name="valor" type="text" className="form-control" placeholder="Filtro" />
                          </div>
                        </div>
                        <br />
                        <div className="row ml-0">
                          <div className="col pl-0">
                            <select onChange={e => setFiltro2(e.target.value)} value={filtro2} id="filtro2" name="filtro2" className="form-control">
                              <option value="cliente.email_cliente">E-mail cliente</option>
                              <option value="cliente.nome_cliente">Nome cliente</option>
                              <option value="status_negociacao.nome_status">Status do cliente</option>
                              <option value="crm.data_registro">Data de registro</option>
                              <option value="crm.data_lembranca">Data de lembrança</option>
                              <option value="newsletter">Newsletter</option>
                              <option value="quiz">Quiz</option>
                            </select>
                          </div>
                          <div className="col">
                            <input onChange={e => setValor2(e.target.value)} value={valor2} id="valor2" name="valor2" type="text" className="form-control" placeholder="Filtro" />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col px-3">
                            <label className="checkbox">
                              <input id="atrasados" type="checkbox" data-toggle="checkbox" name="atrasados" onChange={e => setAtrasados(e.target.checked)} checked={atrasados} />Atrasados
                            </label>
                            <label className="checkbox">
                              <input id="favoritos" type="checkbox" data-toggle="checkbox" name="favoritos" onChange={e => setFavoritos(e.target.checked)} checked={favoritos} />Favoritos
                            </label>
                          </div>
                          <div className="col px-3">
                            <Button onClick={() => fetchData()} type="button" id="search-button" color="default" size="sm" label="Pesquisar" />
                          </div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>

                    {!isLoading ? (
                      <div className="table-responsive">
                        <table className="table">
                          <thead className="text-primary">
                            <th>Cliente</th>
                            <th>E-mail</th>
                            <th>Cidade</th>
                            <th>Responsável</th>
                            <th>Status</th>
                            <th>Data lembrança</th>
                            <th>Ações</th>
                          </thead>
                          <tbody>
                            {renderItems()}
                          </tbody>
                        </table>

                        {error ? (
                          <div>
                            <p className="my-0 text-center alert-error"><b className="text-danger">Erro:</b> {error}</p>
                          </div>
                        ) : (
                          <span>
                            <p className="text-center">Total de itens: {crmData.length}</p>
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className="text-center">
                        <div className="lds-facebook">
                          <div></div>
                          <div></div>
                          <div></div>
                          <span>Carregando...</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Base>
  );
}

export default Crm;
