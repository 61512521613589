const Button = (props) => {

  const type = props.type || 'button';
  const id = props.id || 'button';
  const color = props.color || 'primary';
  const size = props.size || 'md';
  const label = props.label || 'Button';
  const onClick = props.onClick || null;

  return (
    <button onClick={onClick} type={type} id={id} className={`btn btn-${color} btn-${size}`}>{label}</button>
  );
}

export default Button;
