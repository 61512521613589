const Navbar = ({ title, contextData }) => {
  const logout = async (e) => {
    e.preventDefault();
    contextData.logout();
    window.location.href = '/logout';
  };

  return (
    <ul className="nav">
      <nav className="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent">
        <div className="container-fluid">
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button type="button" className="navbar-toggler">
                <span className="navbar-toggler-bar bar1"></span>
                <span className="navbar-toggler-bar bar2"></span>
                <span className="navbar-toggler-bar bar3"></span>
              </button>
            </div>
            <a className="navbar-brand" title="lembrete rápido">{title}</a>
          </div>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation"
            aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navigation">
            <ul id="navbar-wrapper" className="navbar-nav">
              <li className="nav-item">
                <p><b>Usuário:</b> {contextData?.userData?.nome}</p>
                <p><b>Tempo restante de sessão:</b> <span id="session-countdown">60:00</span></p>
              </li>
              <li className="nav-item">
                <a className="btn btn-sm btn-primary" id="perfilModalButton"
                  href="/manager/usuario/editar-perfil"
                  data-toggle="modal" data-target="#perfilModal"
                >
                  Editar perfil
                </a>
              </li>
              <li className="nav-item">
                <a className="btn btn-sm btn-danger" onClick={logout}>Sair</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </ul>
  );
}

export default Navbar;
