import Sidebar from './Sidebar';
import Navbar from './Navbar';

import { UserContext } from '../../contexts/UserContext';

const Base = ({ title, children }) => {
  return (
    <>
      <div className="wrapper">
        <div className="sidebar" data-color="white" data-active-color="danger">
          <div className="logo">
            <a href="/manager" className="simple-text logo-normal text-center">
              Estudar no Exterior
            </a>
          </div>
          <div className="sidebar-wrapper">
            <Sidebar />
          </div>
        </div>
        <div className="main-panel">
          <UserContext.Consumer>
            {contextData => <Navbar title={title} contextData={contextData} />}
          </UserContext.Consumer>
          <div className="content">
            {children}
          </div>
          <footer className="footer footer-black  footer-white ">
            <div className="container-fluid">
              <div className="row">
                <nav className="footer-nav">
                  <ul>
                    <li><a href="https://estudarnoexterior.com.br" target="_blank">Estudar no Exterior</a></li>
                  </ul>
                </nav>
                <div className="credits ml-auto">
                  <span className="copyright">
                    Coded by <a href="https://ramonsouza.com" target="_blank">Ramón Souza</a>
                  </span>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

export default Base;
